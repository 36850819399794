import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../../helpers/config.service';
import { Patterns } from '../../../helpers/patterns';
import { AuthAPIService } from '../../auth/services/auth-api.service';
import { ForgetPasswordComponent } from '../../auth/components/forget-password/forget-password.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styles: ``
})
export class VerifyAccountComponent {
  hideConfirm: boolean = true;
  hide: boolean = true;
  symbols: string = `(! , @, #, $, %, &, *, +, =, {, }, ?, <, >, ", ')`

  loading: boolean = false;
  Token: string = "";
  Phone: string = "";
  selectedCat!: any

  constructor(
    private _config: ConfigService,
    private _formBuilder: FormBuilder,
    private _AuthApiService: AuthAPIService,
    private _router: Router,
    private route: ActivatedRoute) {
  }
  formGroup!: FormGroup;
  isSubmitted = false;
  ngOnInit(): void {

    this.route.params.subscribe(params => {
      console.log('Test Token:', params['token']);
      console.log('Test Phone:', params['phone']);
      if (params['token']) {
        this.Token = params['token'];
        this.CheckToken()
      }
      if (params['phone']) {
        this.Phone = params['phone'].slice(0, 10);
      }
    });



    this.formGroup = this._formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.pattern(Patterns.password), Validators.pattern(Patterns.enTxtWithSpecial)]),
      password_confirmation: new FormControl('', [Validators.required])
    }
    )


    this.confirmPasswordValidation()

  }

  onSend() {
    this.isSubmitted = true;
    if (this.formGroup.invalid) { return; }
    this.loading = true;
    this._AuthApiService.VerifyAccount(this.formGroup.value, this.Token).subscribe({
      next: (res) => {
        this.loading = false;
        this._router.navigate(['/login']);
        this.OpenSuccessMessage();
      },
      error: (error) => {
        this.loading = false;
        // Swal.fire({
        //   title: this._config._translate.instant('swalMsg.Failed'),
        //   text: error.errors[0].massage,
        //   icon: 'error',
        //   confirmButtonText: this._config._translate.instant('swalMsg.Ok'),
        // })
      },
    })

  }

  OpenSuccessMessage() {
    // this.dialogRef.close();
    this._config.SusseccMessage(this._config._translate.instant("setPassword.The password has been set successfully"))
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }


  confirmPasswordValidation() {
    this.formGroup.controls['password_confirmation'].valueChanges.subscribe(data => {
      const pass = this.formGroup.get('password')?.value;
      if (pass !== data && pass.length && data.length) {
        this.formGroup.get('password_confirmation')?.setErrors({ notMatch: true });
      }
    })

    this.formGroup.controls['password'].valueChanges.subscribe(data => {
      const password_confirmation = this.formGroup.get('password_confirmation')?.value;
      if (password_confirmation == data) {
        this.formGroup.get('password_confirmation')?.setErrors(null);
      }
      else if (data.length && password_confirmation?.length && password_confirmation !== data) {
        this.formGroup.get('password_confirmation')?.setErrors({ notMatch: true });
      }
    })

  }

  CheckToken() {
    this._AuthApiService.CheckToken(this.Token).subscribe({
      next: (res: any) => {
      },
      error: (error: any) => {
        this._router.navigate(['/login']);
      },
    })
  }


  get isEnglishOnlyValid() {
    return this.formGroup.controls['password'].value.match(Patterns.enTxtWithSpecial);
  }

  get isMinLengthValid() {
    return this.formGroup.controls['password'].value.length >= 8;
  }

  get isLowerCaseValid() {
    return this.formGroup.controls['password'].value.match(/[a-z]/);
  }

  get isUpperCaseValid() {
    return this.formGroup.controls['password'].value.match(/[A-Z]/);
  }

  get isNumberValid() {
    return this.formGroup.controls['password'].value.match(/\d/);
  }

  get isSymbolValid() {
    return this.formGroup.controls['password'].value.match(/[!@#$%&*+=?{}<>"']/);
  }
}

