<div class="header">
    <h3 class="title"> {{"setPassword.Setting password"|translate}}</h3>
    <p class="sub_title">
        {{"setPassword.Activate registered account, with a mobile number"|translate}}:
    </p>
    <p dir="ltr">
        <bdi>+966 {{Phone}}</bdi>
    </p>
</div>

<form [formGroup]="formGroup" (ngSubmit)="onSend()" id="resetPasswordForm">
    <div class="fields-group mb-3">
        <mat-form-field>
            <mat-label>{{"resetPassword.The password"|translate}}</mat-label>
            <input matInput class="Passowrd" [type]="hide ? 'password' : 'text'" formControlName="password">
            <button mat-icon-button matSuffix type="button" (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <!-- <img src="/assets/images/icons/password_eye.svg" alt="eye" srcset=""> -->
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <!-- <mat-error *ngIf="hasError('password','pattern')">
                {{"form_message.Invalid password pattern"|translate}}</mat-error> -->
            <mat-error *ngIf="hasError('password','required')">
                {{"form_message.Required"|translate}}</mat-error>
        </mat-form-field>
    </div>
    <div class="fields-group mb-3">
        <mat-form-field>
            <mat-label>{{"resetPassword.Confirm the new password"|translate}}</mat-label>
            <input matInput class="Passowrd" [type]="hideConfirm ? 'password' : 'text'"
                formControlName="password_confirmation">
            <button mat-icon-button matSuffix type="button" (click)="hideConfirm = !hideConfirm"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirm">
                <!-- <img src="/assets/images/icons/password_eye.svg" alt="eye" srcset=""> -->
                <mat-icon>{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="hasError('password_confirmation','notMatch')">
                {{"form_message.Password is not match"|translate}}
            </mat-error>
            <mat-error *ngIf="hasError('password_confirmation','required')">
                {{"form_message.Required"|translate}}
            </mat-error>

        </mat-form-field>
    </div>
</form>

<div class="reset_password">
    <div class="title">{{"resetPassword.The new password must be"|translate}}:</div>
    <div class="list">
      <ul [class.isSubmitted]="isSubmitted">
          <li [class.valid]="isEnglishOnlyValid">{{"resetPassword.Be on English language only"|translate}}</li>
          <li [class.valid]="isMinLengthValid">{{"resetPassword.Consists of at least 8 characters"|translate}}</li>
          <li [class.valid]="isLowerCaseValid">{{"resetPassword.Consists of at least 7 small letters (A-Z)"|translate}}</li>
          <li [class.valid]="isUpperCaseValid">{{"resetPassword.Consists of at least 1 Capital letter (A-Z)"|translate}}</li>
          <li [class.valid]="isNumberValid">{{"resetPassword.Consists of at least 1 Number"|translate}}</li>
          <li [class.valid]="isSymbolValid">{{"resetPassword.Consists of at least 1 symbol"|translate}}
              {{symbols}}</li>
      </ul>
    </div>
</div>


<button mat-button class="btn-primary w-100" type="submit" form="resetPasswordForm" [disabled]="loading"
    [ngClass]="{'loading': loading}">
    {{"setPassword.Set password and activate account"|translate}}</button>



<!-- <mat-selection-list class="selection-btns row" [(ngModel)]="selectedCat">
    <div class=""
        *ngFor="let role of roles;let i = index">
        <mat-list-option [value]="role.value">
            {{role.key}}</mat-list-option>
    </div>
</mat-selection-list> -->
